import ApolloClient from 'apollo-client';
import App, { AppContext } from 'next/app';
import React, { ErrorInfo } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import withApolloClient from '../helpers/withApollo';
import { captureException } from '../helpers/errorHelper';

interface Props extends App {
  apolloClient: ApolloClient<{}>;
}

class MyApp extends App<Props> {
  static async getInitialProps({ Component, ctx }: AppContext) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        // @ts-ignore
        scope.setExtra(key, errorInfo[key]);
      });

      captureException(error, 'Error from `componentDidCatch`');
    });

    // This is needed to render errors correctly in development / production
    // @ts-ignore
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, apolloClient } = this.props;

    return (
      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} />
      </ApolloProvider>
    );
  }
}

export default withApolloClient(MyApp);
