import Head from 'next/head';
import React from 'react';
import { getDataFromTree } from '@apollo/react-ssr';
import * as Sentry from '@sentry/browser';
import getQueryData from './getQueryData';
import initApollo from './initApollo';
import { captureException } from './errorHelper';

export interface Props {
  apolloClient: any;
  apolloState: {
    data: object | null;
  };
  router: {
    query: {
      'adhoc-token': string | null;
    };
  };
}

export interface Context {
  Component: any;
  router: {
    query: {
      'adhoc-token': string | null;
    };
  };
  ctx: { req: Request };
}

export default (App: any) =>
  class Apollo extends React.Component<Props> {
    apolloClient: any;

    static displayName = 'withApollo(App)';

    static async getInitialProps(ctx: Context) {
      const { Component, router } = ctx;

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      const apolloState: { data?: any } = {};

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = initApollo(
        {},
        {
          getAuthToken: () => getQueryData(router.query).adhocToken,
        },
      );
      try {
        // Run all GraphQL queries
        await getDataFromTree(
          <App
            {...appProps}
            Component={Component}
            router={router}
            apolloState={apolloState}
            apolloClient={apollo}
          />,
        );
      } catch (error) {
        // Prevent Apollo Client GraphQL errors from crashing SSR.
        // Handle them in components via the data.error prop:
        // http://dev.apollodata.com/react/api-queries.html#graphql-query-data-error
        Sentry.withScope(scope => {
          scope.setExtra('ssr-graphql-fetching', true);
          captureException(error, 'Error while running `getDataFromTree`');
        });
      }

      if (!process.browser) {
        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind();
      }

      // Extract query data from the Apollo store
      if (apollo) {
        apolloState.data = apollo.cache.extract();
      }

      return {
        ...appProps,
        apolloState,
      };
    }

    constructor(props: Props) {
      super(props);

      // `getDataFromTree` renders the component first, the client is passed off as a property.
      // After that rendering is done using Next's normal rendering pipeline
      this.apolloClient =
        props.apolloClient ||
        initApollo(props.apolloState.data, {
          getAuthToken: () => getQueryData(props.router.query).adhocToken,
        });
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />;
    }
  };
